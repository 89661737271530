<template>
  <div>
    <div class="back-color">
      <NavBar class="nav-nar">
        <div slot="title">更换手机号</div>
      </NavBar>
      <div class="mobile-and-password">
        <van-field
          v-model="number"
          :border="false"
          type="number"
          right-icon="cross"
          label="手机号"
          placeholder="输入手机号"
          @click-right-icon="clearNum"
        />
        <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="手机验证码"
        >
          <template #button>
            <van-button
              v-if="time <= 0"
              plain
              size="small"
              type="danger"
              @click="sendCode"
            >
              发送验证码
            </van-button>
            <!-- 验证码计时器 -->
            <div class="timer" v-else>
              <van-count-down :time="time" format="ss">
                <template #default="timeData">
                  <span class="block">{{ timeData.seconds }}</span>
                  <span class="colon">s</span>
                </template>
              </van-count-down>
            </div>
          </template>
        </van-field>
      </div>
      <div class="save-button">
        <van-button round block type="danger" @click="updatePhone">
          保存
        </van-button>
      </div>
    </div>
    <!-- 弹出层 -->
    <van-popup v-model="show">
      <div class="block">
        <div class="title">
          <div class="content">
            {{ titleText }}
          </div>
          <div class="slogan">(注：合并数据导致一些数据丢失，请谨慎操作)</div>
          <div class="radio-box" v-if="chooseList.length > 0">
            <van-radio-group
              v-model="radio"
              direction="horizontal"
              checked-color="#EF4A39"
              @change="radioChange"
            >
              <van-radio
                :name="item.source"
                icon-size="22"
                v-for="(item, index) in chooseList"
                :key="index"
              >
                <template default>
                  <span
                    :style="{ color: radio === item.source ? '#000' : '#999' }"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="button-box">
          <div class="cancel button" @click="cancel">取消绑定</div>
          <div class="confirm button" @click="confirm">合并会员</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import NavBar from "@/layout/nav-bar/nav-bar";
import { commonSend } from "@/api/common";
import { updatePhone, setUpdateInfo } from "@/api/member";
import { Toast } from "vant";

export default {
  name: "",
  components: {
    NavBar,
  },
  data() {
    return {
      number: this.$route.query.phone_num || "",
      sms: "",
      showBtn: true,
      time: 0,
      show: false,
      titleText: "",
      otherId: "",
      mergeId: "",
      chooseList: [],
      radio: 0,
    };
  },
  watch: {
    time: function () {
      console.log("---------------" + this.time);
    },
  },
  methods: {
    clearNum() {
      this.number = "";
    },
    cancel() {
      this.show = false;
    },
    confirm() {
      this.setUpdateInfo();
      this.show = false;
    },
    radioChange(radio) {
      this.radio = radio;
    },
    async sendCode() {
      if (this.number === "") {
        Toast.fail("请输入手机号码");
        return false;
      }
      const res = await commonSend({
        phone: this.number,
        send_type: "update_phone",
      });
      if (res.code * 1 === 1) {
        this.time = 60000;
        let _that = this;
        setTimeout(() => {
          _that.time = 0;
        }, 59900);
      } else {
        Toast.fail(res.msg);
      }
    },
    async setUpdateInfo() {
      const that = this;
      const res = await setUpdateInfo({
        other_id: this.otherId,
        merge_id: this.mergeId,
        choose: this.radio,
        phone: this.number,
      });
      if (res.code * 1 === 1) {
        this.$store.commit("setRemove");
        this.$store.commit("setCookie", res.data);
        Toast.success(res.msg);
        setTimeout(() => {
          that.$router.push({ name: "my" });
        }, 1000);
      } else {
        Toast.fail(res.msg);
      }
    },
    async updatePhone() {
      if (this.number === "") {
        Toast.fail("请输入手机号码");
        return false;
      }
      if (this.sms === "") {
        Toast.fail("请输入验证码");
        return false;
      }
      const ret = await updatePhone({ phone: this.number, code: this.sms });
      if (ret.code * 1 === 1) {
        Toast.success("手机号码修改成功");
        this.sms = "";
        const that = this;
        setTimeout(function () {
          that.$router.go(-1);
        }, 2000);
      } else if (ret.code * 1 === 0) {
        this.sms = "";
        Toast.fail(ret.msg);
      } else if (ret.code * 1 === 2) {
        this.mergeId = ret.data.merge_id;
        this.otherId = ret.data.other_id;
        if (ret.data.choose.length > 0) {
          this.chooseList = ret.data.choose;
          this.radio = ret.data.choose[0].source;
        }
        this.titleText = ret.msg;
        this.show = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-nar {
  background-color: #fff;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.mobile-and-password {
  padding-top: 54px;
}

.back-color {
  padding: 0 12px;
  background-color: #fff;
  height: 100vh;
}

.nav-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  font-size: 20px;
  border-bottom: 1px solid #ededed;
}

.van-cell__value {
  font-size: 16px;
  font-weight: 600;
}

.van-cell--clickable {
  padding: 10px 0;
}

.mobile-flex {
  display: flex;
  justify-content: space-between;

  .mobile {
    font-weight: normal;
    color: #6f6f6f;
  }
}

.van-button--danger {
  border: none;
}

.van-button__content {
  font-size: 16px;
  font-weight: 600;
}

.van-field {
  display: flex;
  align-items: center;
  padding: 14px 0;
  height: 54px;
  border-bottom: 1px solid #ededed;
}

/deep/ .van-field__control {
  font-size: 17px;
}

/deep/ .van-cell__title {
  font-size: 17px;
  color: #000;
}

.save-button {
  margin-top: 40px;
  padding: 0 12px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 52px;
}

.van-count-down {
  font-size: 16px;
}

.van-button__text {
  font-weight: normal;
  font-size: 17px;
}
.van-popup {
  border-radius: 11px;
  overflow: hidden;
}
.van-radio--horizontal {
  margin-right: 51px;
}
/deep/.van-radio__label {
  font-size: 16px;
}
.block {
  width: 350px;
  .title {
    border-bottom: 1px solid #f5f5f5;
    padding: 33px 25px 24px 25px;
    .content {
      font-size: 19px;
      text-align: center;
    }
    .slogan {
      color: #f09e3f;
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .radio-box {
    margin: 20px 0 0 23px;
  }
  .button-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 55px;
    .button {
      width: 99px;
      height: 40px;
      border: 1px solid #e92019;
      border-radius: 20px;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
    }
    .cancel {
      color: #e92019;
    }
    .confirm {
      background: linear-gradient(to right, #fc5656, #f70606);
      color: #fff;
    }
  }
}
</style>
